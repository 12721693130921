const Play = ()=>{


    if (typeof window !== `undefined`){ 
        window.open("https://notakto-play.web.app/", "_self");
  }
   
return null;

}


export default Play;